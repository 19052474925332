// remove preloader
$(window).on('load', () => {
  $('#preloader').fadeOut('slow');
})

$(document).ready(() => {
  objectFitImages();
  // svg4everybody();

  // add target blank to external links
  const siteUrl = window.location.hostname;
  $('a[href*="//"]:not([href*="' + siteUrl + '"])').attr({
    target: '_blank',
    rel: 'noopener noreferrer'
  });

  let windowWidth = $(window).width();

  $(window).on('resize', () => {
    windowWidth = $(window).width();
  });

    $(window).on('scroll', e => {
    if ($(window).scrollTop() > 50 && !$('body').hasClass('scrolled')) {
      return $('body').addClass('scrolled');
    }

    if ($(window).scrollTop() <= 50 && $('body').hasClass('scrolled')) {
      return $('body').removeClass('scrolled');
    }
  });

  // Menu
  const $mobMenuBtn = $('.mob-menu-btn');
  const $header = $('.main-header');
  const $mainNav = $('.main-nav');
  const $secondaryNav = $('.secondary-nav');

  $mobMenuBtn.on('click', function(e) {
    e.preventDefault();

    $(this).toggleClass('active')
    $header.toggleClass('menu-opened')
    $mainNav.add($secondaryNav).stop().fadeToggle()
  })

  // End Menu
});

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}